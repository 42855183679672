import styled from "styled-components";
import "../../font/font.css";
import {
    a1,
    a9096,
    a10950,
    google,
    main2,
    main3,
    main4,
    main5,
    main6,
    a2,
    google2,
    app2,
    ownerLogin,
    headerLogo,
    ownerTel,
    ownerKakao,
    ownerMain1A1,
    ownerMain1,
    ownerMain2A1,
    ownerMain2A2,
    ownerMain2A3,
    ownerMain2Title,
    ownerMain3Title,
    ownerMain3Tel,
    ownerMain3Icon,
    mainImage2x,
    ownerMobile,
    ownerMain2TileWeb,
    ownerMain1Mobile
} from "../../images";

const Main1 = styled.div`
  width: 100%;
  padding: 1rem 0 6rem 0;
  position: absolute;
`;

const Main2 = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 1rem 0 4rem 0;
  text-align: center;
;
  
  div {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const Main3 = styled.div`
  width: 90%;
  border-bottom: 6px solid black;
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
;

  div {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const Header = styled.div`
  height: 15%;
  display: flex;
  justify-content: space-between;
  padding: 0 3%;

  img {
    width: 1.5rem;
  }

`;

const PPuryo = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
  height: 100%;
`;

const TitleWhite = styled.div`
  font-size: 1.7rem;
  line-height: 2.7rem;
  letter-spacing: 2px;
  color: #fff;
  padding-top: 5.5rem;
  text-align: center;
`;

const ContentWhite = styled.div`
  font-size: 0.8rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  color: #fff;
`;

const Tel = styled.div`
  font-size: 0.2rem;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
`;

const Store = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Footer = styled.div`
  width: 100%;
  height: 10vh;
  padding: 40px 0 178px 1px;
  background-color: #484848;
  
  div {
    padding-top: 0.7rem;
    text-align: center;
  }
  
  img {
    width: 10rem;
  }
`;

const FooterFont = styled.div`
  font-size: 0.7rem;
  font-weight: 300;
  line-height: 1rem;
  margin: 4rem 0;
`;

const Main3a1 = styled.div`
  font-size: 0.9rem;
  line-height: 2.2rem;
`;

function OwnerMobile({ history }) {
    console.log('??2');
    const handleMoveLogin = async () => {
        document.location.href = 'https://accounts.kakao.com/login?continue=http%3A%2F%2Fpf.kakao.com%2F_IRSys%2Fchat';
    };
    const handleMoveTel = async () => {
        document.location.href = 'tel:1800-5411';
    };
    const handleMoveKakao = async () => {
        document.location.href = 'http://pf.kakao.com/_IRSys/chat';
    };
    const handleMoveGoogle = async () => {
        document.location.href = 'https://play.google.com/store/apps/details?id=com.rightcode.ppuryo_seller';
    };
    const handleMoveApp = async () => {
        document.location.href = 'https://apps.apple.com/kr/app/%EB%BF%8C%EB%A3%8C-%EC%BB%A4%EB%A8%B8%EC%8A%A4/id1590958016';
    };

    return (
        <>
            <Main1>
                <Header>
                    <div>
                        <PPuryo><img src={headerLogo} style={{ width: '7rem'}} /></PPuryo>
                    </div>
                    {/*<div>*/}
                    {/*    <img src={ownerLogin} style={{ width:'6rem', marginLeft: '2rem', marginTop: '1rem' }} onClick={(e) => handleMoveLogin(e)}/>*/}
                    {/*    <Tel>뿌료 입점문의 02-325-3345 </Tel>*/}
                    {/*</div>*/}
                </Header>
                <Store>
                    <img src={ownerMain1Mobile} style={{ width: '17rem', margin: '25% auto 20px' }}/>
                    <img src={ownerTel} style={{ width: '13rem', margin: '0 auto' }} onClick={(e) => handleMoveTel(e)} />
                    <ContentWhite>
                        전화문의는 전문 상담가와 1:1로 진행됩니다.<br/>( 1800-5411 을 눌러주세요 )
                    </ContentWhite>
                    <img src={ownerKakao} style={{ width: '20rem', margin: '0 auto' }} onClick={(e) => handleMoveKakao(e)}/>
                </Store>
            </Main1>

            <img src={ownerMobile} style={{ width: '100%'}}/>

            <Main3>
                <img src={ownerMain1A1} style={{ width: '80%' }}/><br/>
                <img src={ownerMain1} style={{ width: '80%' }}/>
            </Main3>

            <Main3>
                <img src={ownerMain2Title} style={{ width: '80%', margin: '20px 0'}}/><br/>
                <img src={ownerMain2A1} style={{ width: '80%' }}/><br/>
                <img src={ownerMain2A2} style={{ width: '80%', margin: '30px 0' }}/><br/>
                <img src={ownerMain2A3} style={{ width: '80%', margin: '0 0 20px'  }}/>
            </Main3>
            <Main2>
                <img src={ownerMain3Title} style={{ width: '21rem', margin: '30px 0'  }}/><br/>
                <img src={ownerMain3Tel} style={{ width: '19rem', margin: '0 0 20px'  }} onClick={(e) => handleMoveTel(e)}/><br/>
                <Main3a1>
                    궁금한 상항은 문의해주세요. 사장님을 위한 전용 상담 센터입니다.<br/>
                    운영시간 : 월요일 ~ 금요일 09:00 ~ 21:00
                </Main3a1><br/>
                <img src={ownerKakao} style={{ width: '20rem', margin: '30px 0 50px'  }} onClick={(e) => handleMoveKakao(e)}/><br/>
                <img src={ownerMain3Icon} style={{ width: '6rem' }}/>
            </Main2>

            <Footer>
                <div>
                    <img src={google2} onClick={(e) => handleMoveGoogle(e)}/>&nbsp;&nbsp;&nbsp;
                    <img src={app2} onClick={(e) => handleMoveApp(e)}/>
                </div>
                <FooterFont>
                    (주) 위드미디어<br/> 통신판매: 2021-서울송파-0332 &nbsp;&nbsp;&nbsp;사업자등록번호 : 105-88-10965<br/>
                    서울시 송파구 법원로114 엠스테이트 A동 709호<br/> 제휴문의 : help@ppuryo.com&nbsp;&nbsp;&nbsp; 고객문의 : 1800-5411<br/>
                    COPYRIGHT rightcode@naver.com
                </FooterFont>
            </Footer>
        </>
    )
}

export default OwnerMobile;